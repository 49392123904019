import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Navigation from '../Navigation';
import Footer from '../Footer';
import { Avatar, Heading, ThemeProvider, Pane, majorScale } from 'evergreen-ui';
import theme from './theme';
import { useMediaQuery } from 'usehooks-ts';

import avatarPng from './assets/bat.png';

const Layout = ({ children }) => {
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <ThemeProvider value={theme}>
      <Navigation />
      <AvatarWrapper mobile={mobile}>
        <StyledAvatar size={mobile ? 100 : 180} src={avatarPng} />
        <StyledHeading mobile={mobile} size={900} color="#ffffff">
          Gui Bastos
        </StyledHeading>
      </AvatarWrapper>
      <Content mobile={mobile} marginX={majorScale(2)}>
        {children}
      </Content>
      <Footer />
    </ThemeProvider>
  );
};

const Content = styled(Pane)`
  padding: 140px 40px 80px;
  ${({ mobile }) =>
    mobile &&
    css`
      padding-top: 80px;
    `}
  max-width: 940px;
  margin: 0 auto;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 85px;
  ${({ mobile }) =>
    mobile &&
    css`
      top: 120px;
    `}
  left: 40px;
`;

const StyledAvatar = styled(Avatar)`
  border: 4px solid #ffffff;
  margin-right: 30px;
`;

const StyledHeading = styled(Heading)`
  padding-top: 20px;
  ${({ mobile }) =>
    mobile &&
    css`
      margin-top: 10px;
      padding-top: 0;
    `}
`;

export default Layout;
