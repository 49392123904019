import styled from '@emotion/styled';
import { Button, Heading, Pane, Paragraph } from 'evergreen-ui';

const Card = ({ children }) => {
  return <Pane>{children}</Pane>;
};

const Head = ({ children }) => {
  return <Heading>{children}</Heading>;
};

const Content = ({ children }) => {
  return <StyledPane>{children}</StyledPane>;
};

const Caption = ({ children }) => {
  return <Paragraph>{children}</Paragraph>;
};

const Link = ({ href, children }) => {
  return <Button href={href}>{children}</Button>;
};

const StyledPane = styled(Pane)`
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
`;

export { Card as default, Head, Content, Link, Caption };
