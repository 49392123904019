import styled from '@emotion/styled';
import Layout from '../../components/Layout';
import Card, { Content, Caption } from '../../components/Card';
import { UnorderedList, ListItem, Heading, Paragraph } from 'evergreen-ui';

import questionPng from './assets/questions.png';
import question1Png from './assets/questions1.png';
import question2Png from './assets/questions2.png';
import question4Png from './assets/questions4.png';

const Projects = () => {
  return (
    <Layout>
      <Heading size={800}>Projects</Heading>
      <Heading size={500}>Canadian Citizenship Test Prep</Heading>
      <Paragraph>
        Canadian Citizenship Preparation test was my first experiment using
        React Native and it was used as tool for myself to be able to study for
        the real test.
      </Paragraph>
      <Paragraph>
        Whether you're studying for the Canadian Citizenship exam or testing
        your knowledge of Canadian history, The Canadian Citizenship Test is a
        fun way to help you to achieve that. More than 400 questions are
        included in the app to aid you in your preparation for the official
        test, all of them were taken from the{' '}
        <a href="https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/pub/discover.pdf">
          Discover Canada The Rights and Responsibilities of Citizenship book
        </a>
        .
      </Paragraph>

      <Paragraph>
        The app also includes questions organized into categories, which might
        help you develop in areas where you are lacking. You can challenge
        yourself by choosing different categories, each one has its own number
        of questions. There is an option to set up a timer, that makes the
        experience more like the real test.
      </Paragraph>

      <Paragraph>
        Canadian Citizenship Test Prep keep records of your progress, but does
        not keep your data on any server, all stays in your device so your data
        is protected.
      </Paragraph>

      <Paragraph>
        Available on App Store and{' '}
        <a href="https://play.google.com/store/apps/details?id=com.guibastos.canadian_citizenship_test">
          Google Play
        </a>
      </Paragraph>
      <Heading size={500}>Technologies used:</Heading>
      <UnorderedList>
        <ListItem>React Native</ListItem>
        <ListItem>Expo</ListItem>
        <ListItem>Graph library</ListItem>
      </UnorderedList>
      <Heading size={500}>Features available:</Heading>
      <UnorderedList>
        <ListItem>400+ questions</ListItem>
        <ListItem>10+ categories</ListItem>
        <ListItem>
          Timer to answer all the questions (1.5 minutes per question)
        </ListItem>
        <ListItem>Progress stored in the app</ListItem>
      </UnorderedList>
      <Heading size={500}>Screenshots Samples:</Heading>

      <Cards>
        <Card>
          <Content>
            <StyledImg alt="Question" src={questionPng} />
          </Content>
          <Caption>
            Start a new test, check your progress, or change settings
          </Caption>
        </Card>
        <Card>
          <Content>
            <StyledImg alt="Question" src={question1Png} />
          </Content>
          <Caption>Select the type of the test</Caption>
        </Card>
        <Card>
          <Content>
            <StyledImg alt="Question" src={question2Png} />
          </Content>
          <Caption>More than 400 questions</Caption>
        </Card>
        <Card>
          <Content>
            <StyledImg alt="Question" src={question4Png} />
          </Content>
          <Caption>Simple and modern design</Caption>
        </Card>
      </Cards>
    </Layout>
  );
};

const Cards = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: 75%;

  @media (min-width: 640px) {
    gap: 35px;
    grid-template-columns: 33% 33%;
  }

  @media (min-width: 944px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
`;

export default Projects;
