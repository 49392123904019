import styled from '@emotion/styled'
import Layout from '../components/Layout'
import { Heading, Paragraph, Button } from 'evergreen-ui'
import { Link } from 'react-router-dom'

const Home = () => {
  const handleClick = () => {
    if (window.analytics) {
      window.analytics.track('Project clicked', {
        title: 'Canadian Citizenship Test Prep',
      })
    }
  }
  return (
    <Layout>
      <Heading size={800}>Profile</Heading>
      <Paragraph size={500}>
        Welcome to Guilherme Bastos’ profile page.
      </Paragraph>
      <Paragraph>
        I have participated in a variety of software development projects,
        ranging from web design and front end development to some backend as
        well.
      </Paragraph>
      <Paragraph>
        Professionally working as a Software Developer since 2008.
      </Paragraph>
      <Paragraph>
        As you can see from the link below, I'm now dabbling in other areas such
        as mobile development.
      </Paragraph>
      <Paragraph>Indie Mobile Projects:</Paragraph>
      <StyledLink to='/projects' onClick={handleClick}>
        <Button>Canadian Citizenship Test Prep</Button>
      </StyledLink>
    </Layout>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default Home
