import { mergeTheme, defaultTheme } from 'evergreen-ui';

const theme = mergeTheme(defaultTheme, {
  components: {
    Paragraph: {
      baseStyle: {
        marginY: 10,
      },
    },
    Heading: {
      baseStyle: {
        marginTop: 20,
        marginBottom: 10,
      },
    },
  },
});

export default theme;
