import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import navBgPng from './assets/home.png';
import { UnorderedList, ListItem } from 'evergreen-ui';
import { useLocation } from 'react-router-dom';

const Navigation = () => {
  const { pathname } = useLocation();
  return (
    <NavWrapper>
      <ShadowWrapper background={navBgPng} />
      <Nav>
        <NavItems>
          <NavItem>
            <StyledLink active={pathname === '/' ? 1 : 0} to="/">
              Home
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink
              active={pathname === '/projects' ? 1 : 0}
              to="/projects"
            >
              Projects
            </StyledLink>
          </NavItem>
        </NavItems>
      </Nav>
    </NavWrapper>
  );
};

const NavWrapper = styled.div`
  margin: 0;
  height: 180px;
  position: relative;
`;

const Nav = styled.nav`
  padding: 40px;
  position: absolute;
  top: 0;
  right: 0;
`;

const ShadowWrapper = styled.div`
  background-size: cover;
  background-image: url('${({ background }) => background}');
  width: 100%;
  height: 100%;
  &::before {
    content: '';
    opacity: 0.5;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 0;
    background: rgba(0, 0, 0, 1);
  }
`;

const NavItems = styled(UnorderedList)`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
`;

const NavItem = styled(ListItem)`
  margin: 0;
  padding-left: 20px;
  padding-rigth: 20px;
`;

const StyledLink = styled(Link)`
  color: #e1e3ea;
  padding: 10px 0;
  text-decoration: none;
  font-weight: 600;
  display: inline;
  :hover {
    color: #ffffff;
  }
  ${({ active }) =>
    active &&
    css`
      color: #ffffff;
      border-bottom: 4px solid #66b3ff;
    `}
`;

export default Navigation;
