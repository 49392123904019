import styled from '@emotion/styled';

import twitter from './assets/twitter.svg';
import github from './assets/github.svg';
import linkedin from './assets/linkedin.svg';

const Footer = () => {
  return (
    <Wrapper>
      <div>© Gui Bastos - {new Date().getFullYear()}</div>
      <SocialIcons>
        <a href="https://www.linkedin.com/in/guibbastos/">
          <Image alt="linkedin icon" src={linkedin} />
        </a>
        <a href="https://www.google.com/url?q=https%3A%2F%2Fgithub.com%2FBillbastos&sa=D&sntz=1&usg=AOvVaw3ZVyq32KrAkYMSr4jcIQGz">
          <Image alt="github icon" src={github} width="20" height="20" />
        </a>
        <a href="https://mobile.twitter.com/guigasbastos">
          <Image alt="twitter icon" src={twitter} width="20" height="20" />
        </a>
      </SocialIcons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 80px 40px;
  background-color: #cacdd8;
`;

const SocialIcons = styled.div`
  max-width: 200px;
  display: flex;
  gap: 10px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0.7;
  transition: all 0.5s ease-out;
  &:hover {
    opacity: 1;
  }
`;

export default Footer;
